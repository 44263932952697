body {
  max-width: 100vw;
  overflow-x: hidden;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a {
  color: inherit;
  text-decoration: none;
}

@media (max-width: 480px) {
  .ant-picker-panels {
    flex-direction: column !important;
  }

  .ant-layout .ant-layout-sider-zero-width-trigger {
    top: 10px !important;
    background: #3aaf4a !important;
    font-size: 24px !important;
  }

  .ant-layout-content {
    margin: 0 !important;
    padding: 15px !important;
  }
}
